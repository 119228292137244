import createActivityDetector from './activity_detector';

const TIME_TO_IDLE = 2 * 60 * 1000;

const activityDetector = createActivityDetector({timeToIdle: TIME_TO_IDLE});

const CATEGORIES = {
  ACTIVITY_LOG: 'activity log',
  VERSION_CONTROL: 'version control',
};

window.activityDetector = activityDetector;

window.OpenTheAdvancedEditEvent = function OpenTheAdvancedEditEvent(data) {
  this.key = 'OPEN_THE_ADVANCED_EDIT';
  this.description = 'Open the advanced-edit';
  this.categories = [];
  this.data = data;
};

window.InteractWithTheAutomationEvent = function InteractWithTheAutomationEvent(data) {
  this.key = 'INTERACT_WITH_THE_AUTOMATION';
  this.description = 'Interact with the automation';
  this.categories = [];
  this.data = data;
};

window.UndoEvent = function UndoEvent(data) {
  this.key = 'UNDO';
  this.description = 'Undo';
  this.categories = [];
  this.data = data;
};

window.RedoEvent = function RedoEvent(data) {
  this.key = 'REDO';
  this.description = 'Redo';
  this.categories = [];
  this.data = data;
};

window.CutEvent = function CutEvent(data) {
  this.key = 'CUT';
  this.description = 'Cut';
  this.categories = [];
  this.data = data;
};

window.CopyEvent = function CopyEvent(data) {
  this.key = 'COPY';
  this.description = 'Copy';
  this.categories = [];
  this.data = data;
};

window.IndentParagraphEvent = function IndentParagraphEvent(data) {
  this.key = 'INDENT_PARAGRAPH';
  this.description = 'Indent paragraph';
  this.categories = [];
  this.data = data;
};

window.AlignParagraphEvent = function AlignParagraphEvent(data) {
  this.key = 'ALIGN_PARAGRAPH';
  this.description = 'Align paragraph';
  this.categories = [];
  this.data = data;
};

window.HighlightTextWithColorEvent = function HighlightTextWithColorEvent(data) {
  this.key = 'HIGHLIGHT_TEXT_WITH_COLOR';
  this.description = 'Highlight text with color';
  this.categories = [];
  this.data = data;
};

window.ChangeFontSizeEvent = function ChangeFontSizeEvent(data) {
  this.key = 'CHANGE_FONT_SIZE_EVENT';
  this.description = 'Change font size';
  this.categories = [];
  this.data = data;
};

window.ChangeLineHeightEvent = function ChangeLineHeightEvent(data) {
  this.key = 'CHANGE_LINE_HEIGHT_EVENT';
  this.description = 'Change line height';
  this.categories = [];
  this.data = data;
};

window.TurnOnControlCharactersEvent = function TurnOnControlCharactersEvent(data) {
  this.key = 'TURN_ON_CONTROL_CHARACTERS';
  this.description = 'Turn on control characters';
  this.categories = [];
  this.data = data;
};

window.TurnOffControlCharactersEvent = function TurnOffControlCharactersEvent(data) {
  this.key = 'TURN_OFF_CONTROL_CHARACTERS';
  this.description = 'Turn off control characters';
  this.categories = [];
  this.data = data;
};

window.MoveToThePreviousTenantRequestEvent = function MoveToThePreviousTenantRequestEvent(data) {
  this.key = 'MOVE_TO_THE_PREVIOUS_TENANT_REQUEST';
  this.description = 'Move to the previous tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.MoveToTheNextTenantRequestEvent = function MoveToTheNextTenantRequestEvent(data) {
  this.key = 'MOVE_TO_THE_NEXT_TENANT_REQUEST';
  this.description = 'Move to the next tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.OpenTenantRequestEvent = function OpenTenantRequestEvent(data) {
  this.key = 'OPEN_TENANT_REQUEST';
  this.description = 'Open tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.CloseTenantRequestEvent = function CloseTenantRequestEvent(data) {
  this.key = 'CLOSE_TENANT_REQUEST';
  this.description = 'Close tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.EnterAnIndividualTenantRequestEvent = function EnterAnIndividualTenantRequestEvent(data) {
  this.key = 'ENTER_AN_INDIVIDUAL_TENANT_REQUEST';
  this.description = 'Enter an individual tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.ExitAnIndividualTenantRequestEvent = function ExitAnIndividualTenantRequestEvent(data) {
  this.key = 'EXIT_AN_INDIVIDUAL_TENANT_REQUEST';
  this.description = 'Exit an individual tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.FinishReviewOfTenantRequestsEvent = function FinishReviewOfTenantRequestsEvent(data) {
  this.key = 'FINISH_REVIEW_OF_TENANT_REQUESTS';
  this.description = 'Finish review of tenant requests';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.LoadDocumentEvent = function LoadDocumentEvent(data) {
  this.key = 'LOAD_DOCUMENT';
  this.description = 'Load document';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.DeleteListItemEvent = function DeleteListItemEvent(data) {
  this.key = 'DELETE_LIST_ITEM';
  this.description = 'Delete list-item';
  this.categories = [];
  this.data = data;
};

window.InsertListItemEvent = function InsertListItemEvent(data) {
  this.key = 'INSERT_LIST_ITEM';
  this.description = 'Insert list-item';
  this.categories = [];
  this.data = data;
};

window.LogoutEvent = function LogoutEvent(data) {
  this.key = 'LOGOUT';
  this.description = 'Logout';
  this.categories = [];
  this.data = data;
};

window.AcceptAllTenantRequestsEvent = function AcceptAllTenantRequestsEvent(data) {
  this.key = 'ACCEPT_ALL_TENANT_REQUESTS';
  this.description = 'Accept all tenant requests';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.AcceptIndividualTenantRequestEvent = function AcceptIndividualTenantRequestEvent(data) {
  this.key = 'ACCEPT_INDIVIDUAL_TENANT_REQUEST';
  this.description = 'Accept individual tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.RejectAllTenantRequestsEvent = function RejectAllTenantRequestsEvent(data) {
  this.key = 'REJECT_ALL_TENANT_REQUESTS';
  this.description = 'Reject all tenant requests';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.RejectIndividualTenantRequestEvent = function RejectIndividualTenantRequestEvent(data) {
  this.key = 'REJECT_INDIVIDUAL_TENANT_REQUEST';
  this.description = 'Reject individual tenant request';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.AdjustAllTenantRequestsManuallyEvent = function AdjustAllTenantRequestsManuallyEvent(data) {
  this.key = 'ADJUST_ALL_TENANT_REQUESTS_MANUALLY';
  this.description = 'Adjust all tenant requests manually';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.AdjustIndividualTenantRequestManuallyEvent = function AdjustIndividualTenantRequestManuallyEvent(data) {
  this.key = 'ADJUST_INDIVIDUAL_TENANT_REQUEST_MANUALLY';
  this.description = 'Adjust individual tenant request manually';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.MarkAllTenantRequestsAsResolvedEvent = function MarkAllTenantRequestsAsResolvedEvent(data) {
  this.key = 'MARK_ALL_TENANT_REQUESTS_AS_RESOLVED';
  this.description = 'Mark all tenant requests as resolved';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.MarkIndividualTenantRequestAsResolvedEvent = function MarkIndividualTenantRequestAsResolvedEvent(data) {
  this.key = 'MARK_INDIVIDUAL_TENANT_REQUEST_AS_RESOLVED';
  this.description = 'Mark individual tenant request as resolved';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.PreviewTenantRequestsEvent = function PreviewTenantRequestsEvent(data) {
  this.key = 'PREVIEW_TENANT_REQUESTS';
  this.description = 'Preview tenant requests';
  this.categories = [CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.CompareDocumentsEvent = function CompareDocumentsEvent(data) {
  this.key = 'COMPARE_DOCUMENTS';
  this.description = 'Compare documents';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.DownloadDocumentEvent = function DownloadDocumentEvent(data) {
  this.key = 'DOWNLOAD_DOCUMENT';
  this.description = 'Download document';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.OpenLeftHandEditorSearchEvent = function OpenLeftHandEditorSearchEvent(data) {
  this.key = 'OPEN_LEFT_HAND_EDITOR_SEARCH';
  this.description = 'Open left-hand editor search';
  this.categories = [];
  this.data = data;
};

window.HideLeftHandEditorSearchEvent = function HideLeftHandEditorSearchEvent(data) {
  this.key = 'HIDE_LEFT_HAND_EDITOR_SEARCH';
  this.description = 'Hide left-hand editor search';
  this.categories = [];
  this.data = data;
};

window.MoveToThePreviousEditorSearchResultEvent = function MoveToThePreviousEditorSearchResultEvent(data) {
  this.key = 'MOVE_TO_THE_PREVIOUS_EDITOR_SEARCH_RESULT';
  this.description = 'Move to the previous editor search result';
  this.categories = [];
  this.data = data;
};

window.MoveToTheNextEditorSearchResultEvent = function MoveToTheNextEditorSearchResultEvent(data) {
  this.key = 'MOVE_TO_THE_NEXT_EDITOR_SEARCH_RESULT';
  this.description = 'Move to the next editor search result';
  this.categories = [];
  this.data = data;
};

window.OpenFeedbackPaneEvent = function OpenFeedbackPaneEvent(data) {
  this.key = 'OPEN_FEEDBACK_PANE';
  this.description = 'Open feedback pane';
  this.categories = [];
  this.data = data;
};

window.FeedbackSubmittedEvent = function FeedbackSubmittedEvent(data) {
  this.key = 'FEEDBACK_SUBMITTED';
  this.description = 'Feedback submitted';
  this.categories = [];
  this.data = data;
};

window.OpenContactFormPaneEvent = function OpenContactFormPaneEvent(data) {
  this.key = 'OPEN_CONTACT_FORM_PANE';
  this.description = 'Open contact form pane';
  this.categories = [];
  this.data = data;
};

window.ContactFormSubmittedEvent = function ContactFormSubmittedEvent(data) {
  this.key = 'CONTACT_FORM_SUBMITTED';
  this.description = 'Contact form submitted';
  this.categories = [];
  this.data = data;
};

window.ResizeImageEvent = function ResizeImageEvent(data) {
  this.key = 'RESIZE_IMAGE';
  this.description = 'Resize image';
  this.categories = [];
  this.data = data;
};

window.RotateImageEvent = function RotateImageEvent(data) {
  this.key = 'ROTATE_IMAGE';
  this.description = 'Rotate image';
  this.categories = [];
  this.data = data;
};

window.DeleteImageEvent = function DeleteImageEvent(data) {
  this.key = 'DELETE_IMAGE';
  this.description = 'Delete image';
  this.categories = [];
  this.data = data;
};

window.ResetImageToOriginalSizeEvent = function ResetImageToOriginalSizeEvent(data) {
  this.key = 'RESET_IMAGE_TO_ORIGINAL_SIZE';
  this.description = 'Reset image to original size';
  this.categories = [];
  this.data = data;
};

window.ImportTenantRequestsEvent = function ImportTenantRequestsEvent(data) {
  this.key = 'IMPORT_TENANT_REQUESTS';
  this.description = 'Import tenant requests';
  this.categories = [CATEGORIES.ACTIVITY_LOG, CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.CreateANewDocumentEvent = function CreateANewDocumentEvent(data) {
  this.key = 'CREATE_A_NEW_DOCUMENT';
  this.description = 'Create a new document';
  this.categories = [CATEGORIES.ACTIVITY_LOG, CATEGORIES.VERSION_CONTROL];
  this.data = data;
};

window.CreateNewVersionEvent = function CreateNewVersionEvent(data) {
  this.key = 'CREATE_NEW_VERSION';
  this.description = 'Create new version';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.PrintDocumentEvent = function PrintDocumentEvent(data) {
  this.key = 'PRINT_DOCUMENT';
  this.description = 'Print document';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.ShareDocumentEvent = function ShareDocumentEvent(data) {
  this.key = 'SHARE_DOCUMENT';
  this.description = 'Share document';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.ChangeUserEvent = function ChangeUserEvent(data) {
  this.key = 'CHANGE_USER';
  this.description = 'Change user';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.ChangeStatusEvent = function ChangeStatusEvent(data) {
  this.key = 'CHANGE_STATUS';
  this.description = 'Change status';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.SaveChangesUsingTheAdvancedEditEvent = function SaveChangesUsingTheAdvancedEditEvent(data) {
  this.key = 'SAVE_CHANGES_USING_THE_ADVANCED_EDIT';
  this.description = 'Save changes using the advanced-edit';
  this.categories = [];
  this.data = data;
};

window.DeleteParagraphEvent = function DeleteParagraphEvent(data) {
  this.key = 'DELETE_PARAGRAPH';
  this.description = 'Delete paragraph';
  this.categories = [];
  this.data = data;
};

window.ClickOnALeaseVarEvent = function ClickOnALeaseVarEvent(data) {
  this.key = 'CLICK_ON_A_LEASE_VAR';
  this.description = 'Click on a lease-var';
  this.categories = [];
  this.data = data;
};

window.ToggleDocumentSearchEvent = function ToggleDocumentSearchEvent(data) {
  this.key = 'TOGGLE_DOCUMENT_SEARCH';
  this.description = 'Toggle document search';
  this.categories = [];
  this.data = data;
};

window.EnterSoftBreakEvent = function EnterSoftBreakEvent(data) {
  this.key = 'ENTER_SOFT_BREAK';
  this.description = 'Enter soft-break';
  this.categories = [];
  this.data = data;
};

window.EnterHardSpaceEvent = function EnterHardSpaceEvent(data) {
  this.key = 'ENTER_HARD_SPACE';
  this.description = 'Enter hard-space';
  this.categories = [];
  this.data = data;
};

window.EnterHardBreakEvent = function EnterHardBreakEvent(data) {
  this.key = 'ENTER_HARD_BREAK';
  this.description = 'Enter hard-break';
  this.categories = [];
  this.data = data;
};

window.PasteEvent = function PasteEvent(data) {
  this.key = 'PASTE';
  this.description = 'Paste';
  this.categories = [];
  this.data = data;
};

window.ChangeProvisionEvent = function ChangeProvisionEvent(data) {
  this.key = 'CHANGE_PROVISION';
  this.description = 'Change provision';
  this.categories = [];
  this.data = data;
};

window.InsertImageEvent = function InsertImageEvent(data) {
  this.key = 'INSERT_IMAGE';
  this.description = 'Insert image';
  this.categories = [];
  this.data = data;
};

window.InsertPageBreakEvent = function InsertPageBreakEvent(data) {
  this.key = 'INSERT_PAGE_BREAK';
  this.description = 'Insert page-break';
  this.categories = [];
  this.data = data;
};

window.DeletePreDefinedPageBreakEvent = function DeletePreDefinedPageBreakEvent(data) {
  this.key = 'DELETE_PRE_DEFINED_PAGE_BREAK';
  this.description = 'Delete pre-defined page-break';
  this.categories = [];
  this.data = data;
};

window.DeleteNewlyAddedPageBreakEvent = function DeleteNewlyAddedPageBreakEvent(data) {
  this.key = 'DELETE_NEWLY_ADDED_PAGE_BREAK';
  this.description = 'Delete newly-added page-break';
  this.categories = [];
  this.data = data;
};

window.InsertOutlineAndListItemEvent = function InsertOutlineAndListItemEvent(data) {
  this.key = 'INSERT_OUTLINE_AND_LIST_ITEM';
  this.description = 'Insert outline and list-item';
  this.categories = [];
  this.data = data;
};

window.ShowConceptTitlesEvent = function ShowConceptTitlesEvent(data) {
  this.key = 'SHOW_CONCEPT_TITLES_EVENT';
  this.description = 'Show concept titles';
  this.categories = [];
  this.data = data;
};

window.HideConceptTitlesEvent = function HideConceptTitlesEvent(data) {
  this.key = 'HIDE_CONCEPT_TITLES_EVENT';
  this.description = 'Hide concept titles';
  this.categories = [];
  this.data = data;
};

window.ArchiveDocumentEvent = function ArchiveDocumentEvent(data) {
  this.key = 'ARCHIVE_DOCUMENT';
  this.description = 'Archive document';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.UnarchiveDocumentEvent = function UnarchiveDocumentEvent(data) {
  this.key = 'UNARCHIVE_DOCUMENT';
  this.description = 'Unarchive document';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.LoadDashboardEvent = function LoadDashboardEvent(data) {
  this.key = 'LOAD_DASHBOARD';
  this.description = 'Load dashboard';
  this.categories = [];
  this.data = data;
};

window.LoadArchiveEvent = function LoadArchiveEvent(data) {
  this.key = 'LOAD_ARCHIVE';
  this.description = 'Load archive';
  this.categories = [];
  this.data = data;
};

window.FilterDocumentsEvent = function FilterDocumentsEvent(data) {
  this.key = 'FILTER_DOCUMENTS';
  this.description = 'Filter documents';
  this.categories = [];
  this.data = data;
};

window.SortDocumentsEvent = function SortDocumentsEvent(data) {
  this.key = 'SORT_DOCUMENTS';
  this.description = 'Sort documents';
  this.categories = [];
  this.data = data;
};

window.DownloadBlankFormEvent = function DownloadBlankFormEvent(data) {
  this.key = 'DOWNLOAD_BLANK_FORM';
  this.description = 'Download blank form';
  this.categories = [];
  this.data = data;
};

window.SessionStartEvent = function SessionStartEvent(data) {
  this.key = 'SESSION_START';
  this.description = 'Session start';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.SessionTimeoutEvent = function SessionTimeoutEvent(data) {
  this.key = 'SESSION_TIMEOUT';
  this.description = 'Session timeout';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.SessionEndEvent = function SessionEndEvent(data) {
  this.key = 'SESSION_END';
  this.description = 'Session end';
  this.categories = [CATEGORIES.ACTIVITY_LOG];
  this.data = data;
};

window.ChangeLevelEvent = function ChangeLevelEvent(data) {
  this.key = 'CHANGE_LEVEL';
  this.description = 'Change level';
  this.categories = [];
  this.data = data;
};

// =====================
// Clausebook events
// =====================

window.SignUpToClausebook = function SignUpToClausebook(data) {
  this.key = 'SIGN_UP_TO_CLAUSEBOOK';
  this.description = 'Sign up to clausebook';
  this.categories = [];
  this.data = data;
};

window.CaptureClause = function CaptureClause(data) {
  this.key = 'CAPTURE_CLAUSE';
  this.description = 'Capture clause';
  this.categories = [];
  this.data = data;
};

window.InsertClause = function InsertClause(data) {
  this.key = 'INSERT_CLAUSE';
  this.description = 'Insert clause';
  this.categories = [];
  this.data = data;
};

window.PreviewClause = function PreviewClause(data) {
  this.key = 'PREVIEW_CLAUSE';
  this.description = 'Preview clause';
  this.categories = [];
  this.data = data;
};

(function() {
  function Track() {
    activityDetector.on('active', () => {
      window.track.event(new SessionStartEvent({}));
    });

    activityDetector.on('idle', () => {
      window.track.event(new SessionTimeoutEvent({}));
    });

    window.addEventListener("beforeunload", function(event) {
      window.track.event(new SessionEndEvent({}));

      if (window.lease && window.lease.form) {
        window.localStorage.setItem('last_session_form', JSON.stringify(window.lease.form));
      }
    });

    this.identify = () => {
      if (!window.user) {
        return;
      }

      let USER_COMPANY = "";

      if (window.user.company) {
        USER_COMPANY =  window.user.company.name;
      }

      const USER_EMAIL = window.user.email;
      const USER_ID = window.user.id;

      if (
        window._mixpanel_enabled && 
        typeof mixpanel !== "undefined"
      ) {
        mixpanel.identify(USER_ID);
      }

      if (typeof Raven !== "undefined") {
        Raven.setTagsContext({ company: USER_COMPANY });
        Raven.setUserContext({
          email: USER_EMAIL,
          id: USER_ID,
        });
      }
    };

    this.setProfile = () => {
      const USER_ID = window.user.id;

      if (
        window._mixpanel_enabled && 
        typeof mixpanel !== "undefined"
      ) {
        mixpanel.people.set({
          "USER_ID": USER_ID,
          "$email": window.user.email,
          "company": window.user.company.name,
          "name": window.user.firstName && window.user.lastName ? `${window.user.firstName} ${window.user.lastName}` : '',
          "role": window.user.role,
          "createdAt": window.user.createdAt,
          "updatedAt": window.user.updatedAt,
        });
      }
    };

    this.event = (event) => {
      if (window.isDebug) {
        console.log(event);
      }

      try {
        if (
          window._mixpanel_enabled &&
          typeof mixpanel !== "undefined" &&
          window.user &&
          !window.user.isLeasePilotUser
        ) {
          mixpanel.track(event.description, event.data);
        }

        if (
          window._wootric_enabled &&
          typeof wootric !== "undefined" &&
          window.user &&
          !window.user.isLeasePilotUser
        ) {
          const referer = document.referrer.replace(
            document.location.protocol + "//" + document.location.host,
            ""
          );

          if (
            event.key === "SHARE_DOCUMENT" ||
            event.key === "DOWNLOAD_DOCUMENT" ||
            (event.key === "LOAD_DASHBOARD" && referer.endsWith("/edit"))
          ) {
            let form;

            if (window.lease && window.lease.form) {
              form = window.lease.form;  
            } else {
              form = JSON.parse(window.localStorage.getItem('last_session_form'));
            }

            window.wootricSettings.properties = {};
            window.wootricSettings.properties.role = window.user.role;

            if (form) {
              window.wootricSettings.properties.product = form.product;
              window.wootricSettings.properties.industry = form.name.toLowerCase();
              window.wootricSettings.properties.formType = form.dealType;
            }

            wootric("run");
          }
        }

        if (event.categories.includes(CATEGORIES.ACTIVITY_LOG)) {
          if (!(window.lease || (event.data && event.data.documentId))) {
            return;
          }

          let documentId;

          if (window.lease) {
            documentId = window.lease.id;
          } else if (event.data.documentId) {
            documentId = event.data.documentId;
            delete event.data.documentId;
          }

          const data = {
            event: {
              lease_id: documentId,
              key: event.key,
              data: event.data,
            }
          };
          $.post(`/api/events`, data);
        }
      } catch(ex) {
        console.error(ex);
      }
    };
  }

  window.track = new Track();
})();
